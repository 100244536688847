import * as React from 'react';
import Image from '../Image';

interface Props {
  node: {
    name: 'img';
    attribs: {
      src: string;
      alt?: string;
      class?: string;
    };
  };
}

const MarkdownImage: React.StatelessComponent<Props> = ({ node }) => {
  const img = { ...node.attribs };

  return (
    <Image
      src={img.src}
      alt={img.alt}
      className={img.class}
      // If we want to be fancy in the future, the following `sizes` could be set _for images over 930px_ (parsed from `w_`):
      //   (max-width: 575px) 100vw, (max-width: 767px) 510px, (max-width: 991px) 690px, (max-width: 1199px) 930px, 730px
      maxWidth={930}
    />
  );
};

export default MarkdownImage;
