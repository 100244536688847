import * as React from 'react';
import Link from '../Link';

interface Props {
  href: string;
  node: {
    name: 'a';
    children: any[];
  };
}

const MarkdownLink: React.StatelessComponent<Props> = ({ href, node }) => (
  <Link to={href}>{getAnchorChildren(node.children[0])}</Link>
);

export default MarkdownLink;

/**
 * Recursively traverse through parsed anchor children and return correct JSX elements.
 * A recursive approach is used for links with a deep structure, such as:
 * [**`Test`**](/some-url).
 * Parsed link in this case has a deep children structure and we need to assure
 * that the children are rendered in the correct order:
 * <a><strong><code>Test</code></strong></a>
 */
function getAnchorChildren(node: any, previousChildren: any[] = []): any[] {
  if (node.type === 'text') {
    previousChildren.push(node.data);
  } else if (node.type === 'tag') {
    if (node.name === 'strong') {
      previousChildren.push(<strong key={previousChildren.length}>{getAnchorChildren(node.children[0])}</strong>);
    } else if (node.name === 'code') {
      previousChildren.push(<code key={previousChildren.length}>{getAnchorChildren(node.children[0])}</code>);
    } else if (node.name === 'em') {
      previousChildren.push(<em key={previousChildren.length}>{getAnchorChildren(node.children[0])}</em>);
    }
  }

  if (node.next) {
    return getAnchorChildren(node.next, previousChildren);
  } else {
    return previousChildren;
  }
}
