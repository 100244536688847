import { IBlogpost, IDocsArticle } from '../interfaces/Markdown';

/**
 * Checks the parsed HTML and returns the first featured image in the following order:
 * frontmatter.featuredImage
 * first image in the HTML
 * default "book lover" image
 */
export function getFeaturedImage(post: IBlogpost | IDocsArticle): string {
  if (isBlogpost(post) && post.frontmatter.featuredImage) {
    return post.frontmatter.featuredImage;
  }

  // https://regex101.com/r/2MfLlC/1
  const imgSrcRegex = /<img[^>]+src="([^">]+)"/;
  const parsedHtml = imgSrcRegex.exec(post.html);

  if (parsedHtml) {
    return parsedHtml[1];
  }

  return 'https://res.cloudinary.com/vpimg/image/upload/w_576,h_576/versionpress.com/undraw_book_lover_mkck.jpg';
}

function isBlogpost(post: any): post is IBlogpost {
  return !!post.frontmatter;
}
